<script>
    import Taste from "../lib/components/Taste.svelte";
    import tasteList from "../lib/json/tastes.json";
    import menuLinks from "../lib/json/menu.json"; //Было влом грамотно делать

    const title = 'Оживите свои рецепты с ANGRY SHOCK: ароматизатор и глицерин для кулинарии, мыловарения, рыбалки и косметики!'
</script>

<svelte:head>
    <title>{title}</title>
    <meta name="description" property="og:description" content="Используйте ANGRY SHOCK и глицерин ПК-94 для создания вкусных блюд, красивого мыла, эффективной приманки для рыбы и ухоженной кожи. Рекомендации по применению: 2-3 капли на 500 г продукта. Погрузитесь в мир ароматов и улучшите свои рецепты!">
    <meta property="og:title" content={title}>
    <meta property="og:url" content="https://angryshock.ru/">
</svelte:head>

<section class="startScreen" id="top">
    <div class="container">
        <div class="startScreen__content">
            <img 
                class="startScreen__img order-2"
                src="/images/startScreen.png" 
                width="600"
                height="770"
                alt="ANGRY SHOCK – Пищевой ароматизатор"
            >
            <div class="text_container">
                <h1>ANGRY SHOCK – Пищевой ароматизатор</h1>
                <p>
                    Новый пищевой ароматизатор ANGRY SHOCK – шокирующе вкусная добавка, которую можно применять во множестве направлений: от кулинарии до изготовления свечей.
                </p>
                <a class="btn white" href="#taste">
                    Все вкусы
                </a>
            </div>
        </div>
        <svg class="halfCircle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 668 563" fill="none">
            <path fill="#FBAE3C" d="M668 563c-87-8-175-16-255-43A587 587 0 0 1 68 210C37 143 19 71 1 0h667v563Z"/>
        </svg>
    </div>
</section>
<section class="tastyTaste">
    <div class="container">
        <div class="tastyTaste__content">
            <img 
                class="tastyTaste__img order-2"
                src="/images/leo.png" 
                width="620"
                height="600"
                loading="lazy"
                decoding="async"
                alt="Палитра вкусов"
            >
            <div class="text_container">
                <h2>Палитра вкусов</h2>
                <p>
                    Богатая ароматика линейки ANGRY SHOCK включает в себя несколько десятков вкусов, разработанных технологами из высококачественных ингредиентов. Здесь есть как классические моноароматы, так и будоражащие миксы.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="glycerine">
    <div class="anchor" id="glycerine"/>
    <div class="container">
        <div class="glycerine__content">
            <img 
                class="glycerine__img"
                src="/images/glycerine.png" 
                width="562"
                height="600"
                loading="lazy"
                decoding="async"
                alt="angry shock - глицерин"
            >
            <div class="text_container">
                <h2>Глицерин</h2>
                <p>
                    Вещество, используемое в нескольких отраслях: химическая промышленность, лабораторная практика, пищевая промышленность и т.д.. Вместе с ароматизатором ANGRY SHOCK производится ароматизатор высокой степени очистки, которая достигает 99,9%.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="taste">
    <div class="anchor" id="taste"/>
    <div class="container">
        <h2 class="taste__name">Все вкусы</h2>
        <div class="tasteList">
            {#each tasteList as {name, image, sour, text}}
                <Taste
                    {name}
                    {image}
                    {sour}
                    {text}
                />
            {/each}
        </div>
    </div>
</section>
<section class="contacts">
    <span class="anchor" id="contacts"></span>
    <div class="container">
        <div class="contacts__content">
            <div class="text_container">
                <h2>Контакты</h2>
                <p>
                    Чтобы узнать больше о продуктах ANGRY SHOCK или уточнить информацию по сотрудничеству, свяжитесь с нами.
                </p>
                <div class="contacts__link">
                    <a href="tel:{menuLinks.telephone.replaceAll(/\D/g,'')}">
                        {menuLinks.telephone}
                    </a><br>
                    <a href="mailto:{menuLinks.email}">
                        {menuLinks.email}
                    </a>
                </div>
            </div>
            <img 
                class="contacts__img"
                src="/images/bottle_contacts.png" 
                width="414"
                height="400"
                loading="lazy"
                decoding="async"
                alt="Ароматизаторы ANGRY SHOCK"
            >
        </div>
    </div>
</section>

<style lang="postcss">
    h1, h2{
        margin: 0 * 10px;
    }
    p{
        margin: 0;
    }
    section{
        position: relative;
    }
    .text_container{
        max-width: 100%;
    }
    .startScreen{
        & .btn{
            display: block;
            margin: 40px auto *;
            text-align: center;
            width: 300px;
        }
    }
    .startScreen__img{
        max-width: calc(min(275px, 100%));
        margin: 20px auto;
    }
    .glycerine__img,
    .tastyTaste__img,
    .contacts__img{
        max-width: calc(min(310px, 100%));
        margin: 20px auto;
    }

    .startScreen__content ,
    .tastyTaste__content ,
    .glycerine__content ,
    .contacts__content{
        display: flex;
        flex-wrap: wrap;
        z-index: 1;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }
    .tastyTaste__content{
        align-items: start;
        
        & h2{
            margin-top: 40px;
        }
    }
    .startScreen{
        padding: 90px 0;
        position: relative;

        .halfCircle{
            position: absolute 0 0 * *;
            size: 230px 195px;
        }
    }
    .tasteList{
        display: grid;
        justify-content: space-between;
        gap: 40px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    .anchor{
        position: absolute;
        top: -100px;
    }
    .taste__name{
        margin: 30px *;
    }
    .contacts{
        margin-top: 65px;
    }
    .contacts__link{
        margin-top: 20px;
    }
    @media (min-width:992px){
        .startScreen__content ,
        .tastyTaste__content ,
        .glycerine__content ,
        .contacts__content{
            flex-wrap: nowrap;
        }
        .order-2{
            order: 2;
        }
        .startScreen .halfCircle{
            size: 667px 563px;
        }
        .tastyTaste{
            margin-bottom: -130px;
        }
        .tasteList{
            grid-template-columns: 1fr 1fr 1fr;
        }
        .startScreen__img,
        .glycerine__img,
        .tastyTaste__img,
        .contacts__img{
            flex-basis: 40%;
            max-width: 40%;
        }
        .text_container{
            max-width: 638px;
        }
        .startScreen{
            & .btn{
                margin: * 0;
            }
        }
    }
    @media (min-width: 1680px){
        .startScreen{
            background: url(/images/splash.png) 0 0 / auto 100% no-repeat;
        }
    }
</style>